import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { Link } from 'gatsby';
import TeamManagementImage from '../images/Team-Management-Image.svg';


const Button = styled.button`
  color: white;
  background-color: #0077AF;
  font-weight:500;
  height: 40px;
  width: 160px; 
  border: 1px solid transparent; 
  font-Size: 14px;
  align-self: center;
`

const Option = styled.div`
    background-color: white;
    width:400px;
    height: 100px;
    justify-items: center;
    align-items: center;
    display: grid;
    grid-gap:15px;
    padding: 0 30px;
    margin-bottom: 30px;
    grid-template-columns: auto 1fr;
    

`


const TeamManagement = ({ location }) => {
    //const { siteURL } = useSiteMetadata();
    return (
        <Layout path={`${location.pathname}`} >
            <SEO title="Team Management" />
            <section style={{ "height": "280px", "backgroundColor": "white", "padding": "104px 80px 0px 80px", "display": "grid", "gridTemplateColumns": "1fr 1fr",   width: "1024px" , "margin": "0 auto" }}>
                <div>
                    <h1 style={{ fontSize: "32px", paddingTop: "16px" }}>Team Management</h1>
                    <p style={{ "color": "black", "fontSize": "18px", paddingTop: "16px", width: "430px", paddingBottom: "24px", lineHeight: "25px"}}>Build your cases and assign attorneys to them, so you van be organized and don't need to bookmark them for later</p>
                    <Button id="on-hover-btn" style={{ width: "190px", borderRadius:"1.5px" }}>Request access</Button>
                </div>
                <div style={{ justifySelf: "center" }}>

                    <svg width="277px" height="227px" viewBox="0 0 277 227" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>Team management</title>
                        <defs>
                            <linearGradient x1="50.0010736%" y1="100%" x2="50.0010736%" y2="0%" id="linearGradient-1">
                                <stop stopColor="#808080" stopOpacity="0.25" offset="0%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.12" offset="54%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.1" offset="100%"></stop>
                            </linearGradient>
                            <linearGradient x1="49.9989264%" y1="100%" x2="49.9989264%" y2="0%" id="linearGradient-2">
                                <stop stopColor="#808080" stopOpacity="0.25" offset="0%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.12" offset="54%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.1" offset="100%"></stop>
                            </linearGradient>
                            <linearGradient x1="50%" y1="99.9961327%" x2="50%" y2="0%" id="linearGradient-3">
                                <stop stopColor="#808080" stopOpacity="0.25" offset="0%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.12" offset="54%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.1" offset="100%"></stop>
                            </linearGradient>
                            <linearGradient x1="50%" y1="99.9961327%" x2="50%" y2="0%" id="linearGradient-4">
                                <stop stopColor="#808080" stopOpacity="0.25" offset="0%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.12" offset="54%"></stop>
                                <stop stopColor="#808080" stopOpacity="0.1" offset="100%"></stop>
                            </linearGradient>
                        </defs>
                        <g id="01-Homepage-V4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="01-4_Team-Management" transform="translate(-622.000000, -101.000000)">
                                <g id="Body" transform="translate(0.000000, 68.000000)">
                                    <g id="Hero">
                                        <g id="illustration" transform="translate(622.000000, 33.000000)">
                                            <g id="Group" opacity="0.5" transform="translate(0.000000, 5.000000)" fill="url(#linearGradient-1)" fillRule="nonzero">
                                                <rect id="Rectangle" x="0" y="0.122552204" width="149.906652" height="138.574292" rx="2"></rect>
                                            </g>
                                            <rect id="Rectangle" fill="#F5F5F5" fillRule="nonzero" x="1" y="7" width="147" height="135" rx="2"></rect>
                                            <rect id="Rectangle" fill="#4D92B2" fillRule="nonzero" x="13" y="30" width="25" height="22" rx="2"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="45" y="29" width="98" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="45" y="36" width="98" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="64" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="71" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="81" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="88" width="77" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="98" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="106" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="116" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="10" y="123" width="77" height="3" rx="1.5"></rect>
                                            <g id="Group" opacity="0.5" transform="translate(22.000000, 38.000000)" fill="url(#linearGradient-2)" fillRule="nonzero">
                                                <rect id="Rectangle" x="0.0933476395" y="0.216078886" width="149.906652" height="138.574292" rx="2"></rect>
                                            </g>
                                            <rect id="Rectangle" fill="#FFFFFF" fillRule="nonzero" x="24" y="39" width="147" height="135" rx="2"></rect>
                                            <rect id="Rectangle" fill="#4D92B2" fillRule="nonzero" x="35" y="62" width="25" height="22" rx="2"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="67" y="62" width="98" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="67" y="69" width="98" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="95" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="104" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="113" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="120" width="77" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="130" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="137" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="147" width="133" height="3" rx="1.5"></rect>
                                            <rect id="Rectangle" fill="#E0E0E0" fillRule="nonzero" x="34" y="155" width="77" height="3" rx="1.5"></rect>
                                            <line x1="172" y1="89" x2="173" y2="88" id="Path" stroke="#535461"></line>
                                            <line x1="176" y1="85" x2="228" y2="36" id="Path" stroke="#535461" strokeDasharray="12.58"></line>
                                            <line x1="230" y1="35" x2="231" y2="34" id="Path" stroke="#535461"></line>
                                            <line x1="174" y1="138" x2="175" y2="139" id="Path" stroke="#535461"></line>
                                            <line x1="178" y1="143" x2="230" y2="189" id="Path" stroke="#535461" strokeDasharray="12.44"></line>
                                            <line x1="231" y1="191" x2="232" y2="192" id="Path" stroke="#535461"></line>
                                            <g id="Group" opacity="0.5" transform="translate(193.000000, 143.000000)" fill="url(#linearGradient-3)" fillRule="nonzero">
                                                <ellipse id="Oval" cx="41.4359073" cy="41.9709231" rx="41.4327027" ry="41.7706154"></ellipse>
                                            </g>
                                            <circle id="Oval" fill="#F5F5F5" fillRule="nonzero" cx="235.5" cy="184.5" r="40.5"></circle>
                                            <path d="M257,187.900101 C257,203.089708 247.372001,220 235.5,220 C223.627999,220 214,203.086449 214,187.900101 C214,172.713752 223.627999,165 235.5,165 C247.372001,165 257,172.707235 257,187.900101 Z" id="Path" fill="#FDD835" fillRule="nonzero"></path>
                                            <path d="M234.499684,225 C242.498084,225.011837 250.323242,222.737731 257,218.461029 C255.492864,210.455401 249.752162,210 249.752162,210 L218.115236,210 C218.115236,210 212.697954,210.433416 211,217.795202 C217.879823,222.494348 226.088487,225.011037 234.499684,225 L234.499684,225 Z" id="Path" fill="#4D92B2" fillRule="nonzero"></path>
                                            <path d="M228,200 L241,200 L241,211.139544 C241,214.376184 238.391721,217 235.174246,217 L233.825754,217 C230.608279,217 228,214.376184 228,211.139544 L228,200 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                            <path d="M229.109351,199 L239.887328,199 C240.50184,199 241,199.501029 241,200.11908 L241,210.140696 C241,213.3767 238.391721,216 235.174246,216 L233.825754,216 C230.608279,216 228,213.3767 228,210.140696 L228,200.11908 C228,199.502332 228.496137,199.001841 229.109351,199 Z" id="Path" fill="#FDB797" fillRule="nonzero"></path>
                                            <path d="M228,204.706197 C232.189667,206.417421 236.801599,206.431588 241,204.74613 L241,203 L228,203 L228,204.706197 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                            <circle id="Oval" fill="#FDB797" fillRule="nonzero" cx="235" cy="188" r="18"></circle>
                                            <ellipse id="Oval" fill="#FDB797" fillRule="nonzero" cx="216.5" cy="187.5" rx="1.5" ry="3.5"></ellipse>
                                            <ellipse id="Oval" fill="#FDB797" fillRule="nonzero" cx="251.5" cy="187.5" rx="1.5" ry="3.5"></ellipse>
                                            <path d="M218,178.465631 C218,178.465631 228.176464,192.90895 251.470826,187.971489 C251.470826,187.971489 254.072618,184.686499 247.737961,174.59546 C241.403304,164.504421 223.767517,166.146916 218,178.465631 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                            <path d="M218,177.467759 C218,177.467759 228.176464,191.902551 251.470826,186.974571 C251.470826,186.974571 254.072618,183.689251 247.737961,173.597199 C241.403304,163.505148 223.767517,165.144483 218,177.467759 Z" id="Path" fill="#FDD835" fillRule="nonzero"></path>
                                            <g id="Group" opacity="0.5" transform="translate(194.000000, 0.000000)" fill="url(#linearGradient-4)" fillRule="nonzero">
                                                <ellipse id="Oval" cx="41.5672973" cy="41.9318919" rx="41.4327027" ry="41.9318919"></ellipse>
                                            </g>
                                            <circle id="Oval" fill="#F5F5F5" fillRule="nonzero" cx="235.5" cy="42.5" r="40.5"></circle>
                                            <path d="M236.50601,82 C244.502161,82.0118283 252.325119,79.7377233 259,75.461024 C257.493287,67.4554003 251.7542,67 251.7542,67 L220.113235,67 C220.113235,67 214.697477,67.4334155 213,74.7951973 C219.881498,79.4968142 228.092765,82.013622 236.50601,82 L236.50601,82 Z" id="Path" fill="#4D92B2" fillRule="nonzero"></path>
                                            <circle id="Oval" fill="#333333" fillRule="nonzero" cx="235.5" cy="40.5" r="19.5"></circle>
                                            <path d="M229,57 L242,57 L242,68.138392 C242,71.3756687 239.391721,74 236.174246,74 L234.825754,74 C231.608279,74 229,71.3756687 229,68.138392 L229,57 L229,57 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                            <path d="M230.118458,56 L240.88818,56 C241.502221,56 242,56.5010485 242,57.1190985 L242,67.1406969 C242,70.3766959 239.393719,73 236.178708,73 L234.831248,73 C233.285621,73.0026523 231.802394,72.3865104 230.708536,71.287391 C229.614677,70.1882716 229,68.6964163 229,67.1406969 L229,57.1190985 C229,56.8211392 229.118043,56.53548 229.327988,56.325419 C229.537932,56.1153579 229.822437,55.9982411 230.118458,56 Z" id="Path" fill="#FDB797" fillRule="nonzero"></path>
                                            <path d="M229,61.7061973 C233.189667,63.4174212 237.801599,63.4315878 242,61.7461296 L242,60 L229,60 L229,61.7061973 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                            <circle id="Oval" fill="#FDB797" fillRule="nonzero" cx="235" cy="45" r="18"></circle>
                                            <path d="M223,28.600823 C223,28.600823 230.588665,44.2520992 252,35.1613163 L247.014722,27.2215543 L238.17733,24 L223,28.600823 Z" id="Path" fill="#000000" fillRule="nonzero" opacity="0.1"></path>
                                            <path d="M223,28.6030117 C223,28.6030117 230.588665,44.2506434 252,35.1619773 L247.014722,27.2208042 L238.17733,24 L223,28.6030117 Z" id="Path" fill="#333333" fillRule="nonzero"></path>
                                            <path d="M222.383584,26.0202241 C222.901867,24.4886646 223.79973,23.0972935 225.001888,21.9627776 C228.602055,18.5758808 234.535785,17.8618695 237.573017,14 C237.907262,14.552406 237.979486,15.2131653 237.771826,15.8188623 C237.564166,16.4245592 237.096013,16.9186368 236.483148,17.1789023 C239.389465,17.1602759 242.763804,16.9181329 244.410062,14.6457145 C245.24044,16.478168 244.883677,18.6004057 243.493656,20.0970352 C246.072685,20.2087935 248.825176,21.8696457 248.992093,24.3128059 C249.103371,25.9395098 248.026594,27.482395 246.615983,28.4168184 C245.205372,29.3512418 243.500201,29.7796486 241.817941,30.1304454 C236.924987,31.1580007 219.182708,35.4575902 222.383584,26.0202241 Z" id="Path" fill="#333333" fillRule="nonzero"></path>
                                            <ellipse id="Oval" fill="#FDB797" fillRule="nonzero" cx="216.5" cy="44.5" rx="1.5" ry="3.5"></ellipse>
                                            <ellipse id="Oval" fill="#FDB797" fillRule="nonzero" cx="253.5" cy="44.5" rx="1.5" ry="3.5"></ellipse>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

            </section>
            <div style={{backgroundColor: "#FBFBFB"}}>

           
            <section style={{ backgroundColor: "#FBFBFB", height: "565px", "padding": "40px 80px 0px 80px", display: "grid", gridTemplateColumns: "1fr 1fr",   width: "1024px" , "margin": "0 auto"  }}>
                <div>
                    <h1 style={{ "fontSize": "24px", "fontWeight": "500", paddingTop:"32px" }}>What you can do</h1>
                    <p style={{ paddingTop: "30px", paddingBottom: "30px", color: "#5E5E5E", fontSize: "18px", width: "410px", lineHeight: "25px"  }}>Keep all your cases organized and add your selected attorneys to a case. You can go back any time to see it again.</p>
                    <section>
                        <Option>
                            <h1 style={{ "color": "#124151", "fontSize": "50px", fontWeight: "300" }}>1</h1>
                            <p style={{ color: "#124151", fontSize: "16px" }}>Create detailed cases and add your favorite attorneys for that specific case</p>
                        </Option>
                        <Option>
                            <h1 style={{ "color": "#124151", "fontSize": "50px", fontWeight: "300" }}>2</h1>
                            <p style={{ color: "#124151", fontSize: "16px" }}>Compare multiple attorneys at once from each case and narrow down on your results</p>
                        </Option>

                    </section>


                    {/* <Button style={{ border: "1px solid rgb(0, 119, 175)", color: "rgb(0, 119, 175)", backgroundColor: "white" }}>Request access</Button> */}
                </div>
                <div style={{ justifySelf: "center" }}>
                    <img src={TeamManagementImage} />
                </div>

            </section>
            </div>

        </Layout >

    );
}




export default TeamManagement
